export const Entities = Object.freeze({
  Schedules: "schedules",
  Reports: "reports",
  Weeks: "weeks",
  StudentReports: "student_reports",
  GroupSessions: "group_sessions",
  GroupSessionStudents: "group_session_students",
  ProgramProgresses: "program_progresses",
  Students: "students",
  Tutors: "tutors",
  Tutor: "tutor",
  Autocomplete: "autocomplete",
  Schools: "schools",
  Educatables: "educatables",
  Programs: "programs",
  Connections: "connections",
  User: "user",
  Dashboard: "dashboard",
  Sessions: "sessions",
  Openings: "openings",
  Applications: "applications",
})

export const Methods = Object.freeze({
  Get: "GET",
  Put: "PUT",
  Post: "POST",
  Delete: "DELETE",
  Patch: "PATCH",
})

export const Roles = Object.freeze({
  Admin: "admin",
  Parent: "parent",
  Tutor: "tutor",
  School_Admin: "school_admin",
})

export const SearchParamsKeys = Object.freeze({
  Page: "page",
  Sort: "sort",
  RowsPerPage: "rowsPerPage",
})

export const Sort = Object.freeze({
  ASC: "ASC",
  DESK: "DESK",
})

export const MeetingFormats = Object.freeze({
  Online: "online",
  Offline: "offline",
  LessonSpace: "lessonspace",
})

export const Grades = Object.freeze({
  kindergarten: {
    key: "kindergarten",
    name: "Kindergarten",
  },
  firstGrade: {
    key: "first_grade",
    name: "1st Grade",
  },
  secondGrade: {
    key: "second_grade",
    name: "2nd Grade",
  },
  thirdGrade: {
    key: "third_grade",
    name: "3rd Grade",
  },
  fourthGrade: {
    key: "fourth_grade",
    name: "4th Grade",
  },
  fifthGrade: {
    key: "fiveth_grade",
    name: "5th Grade",
  },
  sixthGrade: {
    key: "sixth_grade",
    name: "6th Grade",
  },
  seventhGrade: {
    key: "seventh_grade",
    name: "7th Grade",
  },
  eighthGrade: {
    key: "eightth_grade",
    name: "8th Grade",
  },
  ninthGrade: {
    key: "nineth_grade",
    name: "9th Grade",
  },
  tenthGrade: {
    key: "tenth_grade",
    name: "10th Grade",
  },
  eleventhGrade: {
    key: "eleventh_grade",
    name: "11th Grade",
  },
  twelfthGrade: {
    key: "twelveth_grade",
    name: "12th Grade",
  },
})

export const LearningLevel = [
  {
    key: "preschool",
    name: "Preschool",
  },
  {
    key: "elementary",
    name: "Elementary",
  },
  {
    key: "middle_school",
    name: "Middle School",
  },
  {
    key: "high_school",
    name: "High School",
  },
  {
    key: "other",
    name: "Other",
  },
]

export const STUDENT_URLS = Object.freeze({
  signIn: "/users/students/sign_in",
  signOut: "/users/students/sign_out",
  dashboard: "/students/dashboard",
})

export const BASE_URL = process.env.HOST

export const WEEKDAYS = [
  { value: "sunday", label: "Sunday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
]

export const SHORT_WEEKDAYS = [
  { value: "sun", label: "Sunday" },
  { value: "mon", label: "Monday" },
  { value: "tue", label: "Tuesday" },
  { value: "wed", label: "Wednesday" },
  { value: "thu", label: "Thursday" },
  { value: "fri", label: "Friday" },
  { value: "sat", label: "Saturday" },
]

export const DAYPERIODS = [
  { value: "m", label: "Morning", shortLabel: "am" },
  { value: "d", label: "Noon", shortLabel: "noon" },
  { value: "e", label: "Evening", shortLabel: "pm" },
]

export const EXECUTIVE_FUNCTION_DOMAIN = "executive_function"

export const BREAK_REASONS = [
  "Student no longer needs tutoring",
  "Scheduling issue",
  "Location issue",
  "Qualification issue",
  "Personality fit",
]

export const SPECIAL_TREATMENT_REQUIREMENTS = {
  adhd: "If you feel you can support students with ADHD, please add the appropriate Executive Function program in the section above. If you have any special training or attended a conference on students who have ADHD, please include your certificate below.",
  dyslexia:
    "Please note evidence of training in multi-sensory phonics curriculum is REQUIRED for approval to support students with dyslexia. " +
    "You can upload a training certificate or a screenshot of evidence you attended training in the section below ",
  // dysgraphia: "You need to upload dysgraphia certificate",
  // language_based_learning_disability: "You need to upload language_based_learning_disability certificate",
  // dyscalculia: "You need to upload dyscalculia certificate",
}

export const INTERVIEW_DURATION = 30
export const SESSION_DURATIONS = Array(12)
  .fill(1)
  .map((value, idx) => (value + idx) * 15)
SESSION_DURATIONS.splice(2, 0, 40)

export const UPCOMING_INTERVIEW = "upcoming"
export const REQUESTED_INTERVIEW = "waiting_for_accept"
export const TIME_SUGGESTED_INTERVIEW = "new_time_suggested"
export const COMPLETED_INTERVIEW = "completed"
export const CANCELLED_INTERVIEW = "canceled"
export const ALL_PAST_INTERVIEW = "all_past"

export const PENDING_REQUEST = "pending"
export const DECLINED_REQUEST = "declined"
export const CONFIRMED_REQUEST = "confirmed"

export const MEDIA_SM_BREAKPOINT = 576
export const MEDIA_MD_BREAKPOINT = 768
export const MEDIA_LG_BREAKPOINT = 992
export const MEDIA_XL_BREAKPOINT = 1200
export const MEDIA_XXL_BREAKPOINT = 1390

export const INTERVIEW_CALENDAR_MIN_TIME = "07:00:00"
export const INTERVIEW_CALENDAR_MAX_TIME = "22:00:00"

export const DEGREE_ABBREVIATION = {
  doctorate: "PhD",
  masters_degree: "MS",
  bachelors_degree: "BS",
  associate_degree: "AS",
}

export const NAV_CONFIG = {
  tutor: [
    { path: "/tutor/dashboard", label: "Dashboard" },
    { path: "/tutor/calendar", label: "Calendar" },
    { path: "/tutor/sessions", label: "Sessions", hasNew: "hasSessionRequests" },
    { path: "/tutor/interviews", label: "Interviews" },
    { path: "/tutor/parents", label: "Clients", hasNew: "hasInterviewRequests" },
    { path: "/tutor/chats", label: "Messages", hasNew: "hasNewMessage" },
  ],
  admin: [
    { path: "/admin/dashboard", label: "Dashboard" },
    { path: "/admin/calendar", label: "Calendar" },
    { path: "/admin/sessions", label: "Sessions" },
    { path: "/admin/timesheets", label: "Timesheets" },
    { path: "/admin/users", label: "Users" },
    { path: "/admin/chats", label: "Messages" },
    { path: "/openings", label: "Openings", permission: "can_manage_openings" },
    { path: "/admin/sysadmin", label: "SYS-Admin", permission: "can_manage_sys_admin" },
  ],
  parent: [
    { path: "/parent/calendar", label: "Calendar" },
    { path: "/parent/sessions", label: "Sessions" },
    { path: "/parent/payments", label: "Payments" },
    { path: "/parent/interviews", label: "Interviews", hasNew: "hasInterviewRequests" },
    { path: "/parent/find-tutor", label: "Find a Tutor" },
    { path: "/parent/chats", label: "Messages", hasNew: "hasNewMessage" },
  ],
  school_admin: [
    { path: "/school_admin/dashboard", label: "Dashboard", name: "dashboard" },
    { path: "/school_admin/students", label: "Students", name: "students" },
    {
      path: "/school_admin/school_admins",
      label: "School Admins",
      name: "school_admin",
      permission: "can_view_school_admin_list",
    },
  ],
}

export const YES_NO_OPTIONS = ["Yes", "No"]
export const RECEIVE_SESSIONS_OPTIONS = {
  YES: "Yes",
  NO: "No",
}

export const ORGANIZATION_LIST = [
  {
    title: "All Organizations",
    value: "all",
  },
  {
    title: "Braintrust",
    value: "braintrust",
  },
  {
    title: "Smarten Up",
    value: "1",
  },
  {
    title: "Partnership",
    value: "2",
  },
]

export const ALL_TUTORS_LIST = [
  {
    id: "all",
    title: "All tutors",
  },
]

export const INITIAL_PAGINATION = { pageSize: 20, page: 0, paginate: true }

export const BASE_FILTER_API_PARAM_MAP = {
  name: "name",
  email: "email",
  groups: "groups",
  statuses: "status",
}

export const ADMIN_FILTER_API_PARAM_MAP = {
  ...BASE_FILTER_API_PARAM_MAP,
  grades: "grades",
  schools: "schools",
  parent: "parent",
  assignments: "assignments",
}

export const FILTER_DISPLAY_NAMES = {
  name: "Name",
  email: "Email",
  groups: "Groups",
  grades: "Grades",
  schools: "Schools",
  parent: "Parent",
  assignments: "Assignments",
  statuses: "Status",
}
export const DEFAULT_TIMEOUT = 15000

// Server response status codes
export const HTTP_STATUS = Object.freeze({
  UNPROCESSABLE_ENTITY: 422,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
})

// Frontend Error Messages
export const ERROR_MESSAGES = Object.freeze({
  NETWORK: "Network error",
  SERVER: "Server error",
  SESSION_EXPIRED: "Session expired",
  NO_SESSION_FOUND: "No session found",
  INVALID_TOKEN: "Invalid token",
  USER_ALREADY_LOGGED_IN: "User already logged in",
  INVALID_ROLE: "Invalid role",
  INVALID_EMAIL_OR_PASSWORD: "Invalid email or password",
  USER_NOT_FOUND: "User not found",
  USER_ALREADY_EXISTS: "User already exists",
  INVALID_CREDENTIALS: "Invalid credentials",
  INVALID_EMAIL_FORMAT: "Invalid email format",
  INVALID_PASSWORD_FORMAT: "Invalid password format",
  CANT_SUBMIT: "Can't submit",
  FORBIDDEN: "You don't have permission to access this resource",
  NOT_FOUND: "The requested resource was not found",
})

// Map backend status codes to frontend error messages
export const SERVER_ERROR_MAP = Object.freeze({
  [HTTP_STATUS.BAD_REQUEST]: ERROR_MESSAGES.INVALID_CREDENTIALS,
  [HTTP_STATUS.UNAUTHORIZED]: ERROR_MESSAGES.SESSION_EXPIRED,
  [HTTP_STATUS.FORBIDDEN]: ERROR_MESSAGES.FORBIDDEN,
  [HTTP_STATUS.NOT_FOUND]: ERROR_MESSAGES.NOT_FOUND,
  [HTTP_STATUS.UNPROCESSABLE_ENTITY]: ERROR_MESSAGES.CANT_SUBMIT,
  [HTTP_STATUS.INTERNAL_SERVER_ERROR]: ERROR_MESSAGES.SERVER,
})
// Error Generators
export const generateSubmitError = reason => `Can't submit: ${reason}`

// Function to get error message based on status code
export const getErrorMessage = statusCode => {
  return SERVER_ERROR_MAP[statusCode] || ERROR_MESSAGES.SERVER
}
// Export individual constants for backward compatibility
export const ERROR_NETWORK = ERROR_MESSAGES.NETWORK
export const ERROR_SERVER = ERROR_MESSAGES.SERVER
export const ERROR_CANT_SUBMIT = ERROR_MESSAGES.CANT_SUBMIT

// Export the entire map for easier access and future expansion
export { SERVER_ERROR_MAP as ERROR_TYPES }

export const BRT_QRCODE_URL = "https://btstudent.com"
